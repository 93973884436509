import styled from 'styled-components';

import { colors } from '../../constants/colors';
import { sizes } from '../../constants/sizes';

const Quote = styled.blockquote`
    margin-bottom: ${sizes.md}px;
    &:last-child {
        margin-bottom: 0;
    }
`

const QuoteFooter = styled.footer`
    color: ${props => props.textColor || colors.base.green};
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: .6px;
    margin-top: 10px;
`

export { QuoteFooter };
export default Quote;
