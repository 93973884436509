import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import Band from '../components/Band/Band';
import { Container, ContainerWide } from '../components/Grid/Grid';
import SEO from '../components/SEO';
import GridCards, { Card, CardOverlayTitle } from '../components/GridCards/GridCards';
import DiscoverMore from '../components/DiscoverMore/DiscoverMore';
import Cover from '../components/Cover/Cover';
import Button from '../components/Button/Button';
import IconCircle from '../components/IconCircle/IconCircle';
import Quote, { QuoteFooter } from '../components/Quote/Quote';
import BR from '../components/BR';

import icon1 from '../images/icons/icon-4.svg';
import icon2 from '../images/icons/icon-3.svg';
import icon3 from '../images/icons/icon-2.svg';
import icon4 from '../images/icons/icon-1.svg';
import icon5 from '../images/icons/icon-5.svg';

import cardImageLogo from '../images/card-logo-light.svg';
import { colors } from '../constants/colors';

const ConsumersPage = ({ data }) => (
    <Layout>

        <SEO title="Consumers" description="Promoting new ethical brands to meet consumer demands for honesty." />

        <Band size="xs" pb={0}>
            <ContainerWide>
                <GridCards>
                    <Card image={cardImageLogo} theme="blue">
                        <CardOverlayTitle>Consumers</CardOverlayTitle>
                    </Card>
                    <Card image={data.cardImage1} />
                    <Card image={data.cardImage2} />
                </GridCards>
            </ContainerWide>
        </Band>

        <Band size="sm">
            <Container>
                <DiscoverMore>
                    <p>Promoting new ethical brands to meet consumer demands for honesty.</p>
                    <Button href="#more">Discover More</Button>
                </DiscoverMore>
            </Container>
        </Band>

        <Band theme="blue" size="md" id="more">
            <Container>
                <h2 style={{ marginBottom: 15 }}>No hidden truths</h2>
                <Quote>
                    <p style={{ lineHeight: 1.65 }}>"We are facing a man made disaster on a global scale. The scientific evidence is clear that if we do not <BR />
                        take dramatic action within the next decade, we could face irreversible damage to the natural world <BR />
                        on which we depend and the collapse of our societies."</p>
                    <QuoteFooter textColor="#fff" style={{ fontWeight: `normal`, fontSize: 14 }}>Sir David Attenborough BBC climate change the facts 2019</QuoteFooter>
                </Quote>
            </Container>
        </Band>

        <Cover image={data.coverImage1} />

        <Band size="lg">
            <Container>
                <h2>Supporting great brands. <BR /> Working with the planet not against it.</h2>

                <IconCircle icon={icon1} />
                <Quote>
                    <p>"Consumer demand for lower carbon products and services is growing. <BR />
                        Research by the Carbon Trust revealed that 45% of shoppers would be prepared <BR />
                        to stop buying their favourite brands if they refused to commit to measuring <BR />
                        their product carbon foot print, this rate has doubled over the past year. <BR />
                        56% of consumers would be more loyal to a brand if they could see at a glance <BR />
                        that it was taking steps to reduce its carbon footprint."</p>
                    <QuoteFooter>The carbon trust</QuoteFooter>
                </Quote>

                <IconCircle icon={icon2} />
                <Quote>
                    <p>"A Nielsen study of consumers in 60 countries found that while 66% of global <BR />
                        consumers are willing to pay more for sustainable goods 73% of millennials would."</p>
                    <QuoteFooter>Nielsen</QuoteFooter>
                </Quote>

                <IconCircle icon={icon3} />
                <Quote>
                    <p>"Half of UK adults are adopting vegan buying behaviour as the number of full time <BR />
                        vegans grows four fold in 10 years."</p>
                    <QuoteFooter>The vegan society</QuoteFooter>
                </Quote>

                <IconCircle icon={icon4} />
                <Quote>
                    <p>"Demand for organic food is at its highest for more than a decade, according to <BR />
                        major retailers."</p>
                    <QuoteFooter>The Guardian</QuoteFooter>
                </Quote>

                <IconCircle icon={icon5} />
                <Quote>
                    <p>In April 2018 the influenster polled 7,675 women in the USA, to find out their thoughts and <BR />
                        shopping behaviour when it comes to 'Green Beauty'. 8 out of 10 women had purchased a <BR />
                        green beauty product over the past year and the most appealing top 2 claims to these women <BR />
                        were natural ingredients and cruelty free.</p>
                    <QuoteFooter>Influenster</QuoteFooter>
                </Quote>

                <h2 style={{ color: colors.base.green }}>We believe consumers are already making informed choices.</h2>

            </Container>
        </Band>

    </Layout>
);

export const pageQuery = graphql`
    query {
        cardImage1: file(relativePath: { eq: "card-5.jpg" }) {
            ...cardImageFragment
        }
        cardImage2: file(relativePath: { eq: "card-4.jpg" }) {
            ...cardImageFragment
        }
        coverImage1: file(relativePath: { eq: "cover-3.jpg" }) {
            ...coverImageFragment
        }
    }
`

export default ConsumersPage;
