import React from 'react';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';

import { colors } from '../../constants/colors';
import { sizes } from '../../constants/sizes';

const BasicIconCircle = ({ icon, className }) => (
    <div className={className}>
        <SVG src={icon} />
    </div>
);

const IconCircle = styled(BasicIconCircle)`
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 1px solid ${colors.base.green};
    margin: ${sizes.md}px auto 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        width: 32px;
        height: 32px;
    }
`

export default IconCircle;
